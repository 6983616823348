<template>
  <div class="step2">
    <div class="book" @click="toNextStep">
      <div class="day">
        <div class="weekday">{{ info.date_yyyy_mm_dd | weekday }}</div>
        <div class="time">{{ info.time }}</div>
      </div>
      <div class="team">
        <div class="team-item">
          <!-- <img class="img" :src="info.zteam_logo_adress" alt="" /> -->
          <span class="text">{{ info.zteam }}</span>
        </div>

        <div class="vs">VS</div>

        <div class="team-item">
          <!-- <img class="img" :src="info.kteam_logo_adress" alt="" /> -->
          <span class="text">{{ info.kteam }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getShowAllDataById } from "@/api/fenxi.js";
export default {
  filters: {
    weekday(val) {
      var date = new Date(val);
      var dayIndex = date.getDay(); //获取存储当前日期
      var weekday = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
      return weekday[dayIndex];
    }
  },
  data() {
    return {
      id: 0,
      info: {}
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getShowAllDataById();
  },
  methods: {
    toNextStep() {
      this.$router.push({
        path: "/display-fenxi/jibenmian/1",
        query: {
          id: this.id
        }
      });
    },
    getShowAllDataById() {
      getShowAllDataById(this.id).then(res => {
        this.$set(this, "info", res.data);
        this.info.zteam_logo_adress = this.info.zteam_logo_adress.replace(
          /small/,
          "big"
        );
        this.info.kteam_logo_adress = this.info.kteam_logo_adress.replace(
          /small/,
          "big"
        );
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.step2 {
  background-image: url("../../assets/display/step2/bg.png");
  background-repeat: no-repeat;
  min-height: 1080px;
  background-position: center top;
  position: relative;
  background-color: #000;

  .book {
    cursor: pointer;
    width: 567px;
    height: 924px;
    background-image: url("../../assets/display/step2/book.png");
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .day {
      text-align: center;
      font-family: "楷体", "楷体_GB2312";
      position: absolute;
      left: 217px;
      top: 308px;
      font-size: 60px;
      color: #472309;
      line-height: 42px;
    }
    .team {
      position: absolute;
      right: 93px;
      top: 65px;
      .team-item {
        font-family: "楷体", "楷体_GB2312";
        font-size: 52px;
        font-weight: 400;
        color: #472309;
        line-height: 57px;
        writing-mode: vertical-lr;
        .img {
          height: 50px;
        }
      }
      .vs {
        font-size: 41px;
        font-weight: bold;
        color: #472309;
        line-height: 61px;
      }
    }
  }
}
</style>
